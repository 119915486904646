import React from 'react';

import styles from './not-authorized-content.module.sass';

type NotAuthorizedContentProps = {
  children: React.ReactNode;
};

const NotAuthorizedContent: React.FC<NotAuthorizedContentProps> = ({ children }) => (
  <div className={styles.content}>{children}</div>
);

export default NotAuthorizedContent;
