import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';

import Content from '@components/layout/NotAuthorizedContent/NotAuthorizedContent';
import MainLayout from 'src/components/layout/MainLayout';
import s from 'src/components/pageStyles/404.module.sass';

const Error404Page = () => {
  const { t } = useTranslation('errors');

  return (
    <MainLayout noIndex isHeading={false}>
      <Content>
        <h1 className={s.header}>{t('Errors.404.title')}</h1>
        <p className={s.p}>{t('Errors.404.description')}</p>
        <p className={s.p}>{t('Errors.404.additional')}</p>
      </Content>
    </MainLayout>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale as string, ['common', 'errors'])),
  },
});

export default Error404Page;
